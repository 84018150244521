<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Users">

                <b-button v-if="$hasPermission($permissions.AddUsers)" variant="primary" @click="addUserModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="users" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="$router.push(`/user/${props.row.id}`)" class="mr-1">
                            <feather-icon icon="EyeIcon"/>
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.formattedRow[props.column.field] === 'Yes'" variant="light-success">
                            Yes
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            No
                        </b-badge>
                    </span>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add role" v-model="addUserModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Enabled</label>
                    <b-form-checkbox v-model="addObject.enabled" switch>Enabled</b-form-checkbox>
                </b-form-group>

                <b-form-group>
                    <label>Username</label>
                    <b-form-input v-model="addObject.username"/>
                </b-form-group>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Last name</label>
                    <b-form-input v-model="addObject.last_name"/>
                </b-form-group>

                <b-form-group>
                    <label>Phone number</label>
                    <b-form-input v-model="addObject.phone_number"/>
                </b-form-group>

                <b-form-group>
                    <label>Password</label>
                    <b-form-input type="password" v-model="addObject.password"/>
                </b-form-group>

                <b-form-group>
                    <label>Assigned distribution vehicle</label>
                    <v-select
                        v-model="addObject.assigned_vehicle_id"
                        :reduce="type => type.id"
                        label="name"
                        :options="vehicles"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Roles</label>
                    <v-select
                        v-model="addObject.roles"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="roles"
                    />
                </b-form-group>


                <b-form-group>
                    <label>Areas</label>
                    <v-select
                        v-model="addObject.areas"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="areas"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Territories</label>
                    <v-select
                        v-model="addObject.territories"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="territories"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Districts</label>
                    <v-select
                        v-model="addObject.districts"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="districts"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addUserModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addUser">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BBadge, BButton, BCard, BFormCheckbox, BFormGroup, BFormInput, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'

    export default {
        components: {
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            vSelect,
            BFormGroup,
            BFormInput,
            BModal,
            BFormCheckbox,
            BBadge
        },
        data() {
            return {
                dataLoaded: false,
                usersRaw: [],
                roles: [],
                vehicles: [],

                areas: [],
                territories: [],
                districts: [],

                columns: [
                    {
                        label: 'Enabled',
                        displayType: 2,
                        field: 'enabled_text',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Username',
                        displayType: 0,
                        field: 'username',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Last name',
                        displayType: 0,
                        field: 'last_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Phone number',
                        displayType: 0,
                        field: 'phone_number',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Distribution vehicle',
                        displayType: 0,
                        field: 'distribution_vehicle',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Roles',
                        displayType: 0,
                        field: 'role_names',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Areas',
                        displayType: 0,
                        field: 'area_names',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Territories',
                        displayType: 0,
                        field: 'territory_names',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Districts',
                        displayType: 0,
                        field: 'district_names',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addUserModalActive: false,
                addObject: {
                    enabled: true,
                    name: '',
                    last_name: '',
                    phone_number: '',
                    username: '',
                    password: '',
                    assigned_vehicle_id: this.nullObjectId,
                    roles: [],
                    areas: [],
                    territories: [],
                    districts: []
                }
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/user')
                loadPromise.then(function(response) {
                    thisIns.usersRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const rolesLoadPromise = this.$http.get('/api/management/v1/role')
                rolesLoadPromise.then(function(response) {
                    thisIns.roles = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const vehiclesLoadPromise = this.$http.get('/api/management/v1/distribution_vehicle')
                vehiclesLoadPromise.then(function(response) {
                    thisIns.vehicles = [{name: 'None', id: thisIns.nullObjectId}].concat(response.data)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const areasLoadPromise = this.$http.get('/api/management/v1/area')
                areasLoadPromise.then(function(response) {
                    thisIns.areas = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const territoriesLoadPromise = this.$http.get('/api/management/v1/territory')
                territoriesLoadPromise.then(function(response) {
                    thisIns.territories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const districtsLoadPromise = this.$http.get('/api/management/v1/district')
                districtsLoadPromise.then(function(response) {
                    thisIns.districts = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([
                    loadPromise, rolesLoadPromise, vehiclesLoadPromise, areasLoadPromise, territoriesLoadPromise,
                    districtsLoadPromise
                ]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addUser() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/user', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('User added')
                    thisIns.addUserModalActive = false
                    thisIns.addObject = {
                        enabled: true,
                        name: '',
                        last_name: '',
                        phone_number: '',
                        username: '',
                        password: '',
                        assigned_vehicle_id: thisIns.nullObjectId,
                        roles: [],
                        areas: [],
                        territories: [],
                        districts: []
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        computed: {
            users() {
                return this.usersRaw.map((user, index) => {
                    return {
                        index,
                        id: user.id,
                        enabled: user.enabled,
                        enabled_text: (user.enabled) ? 'Yes' : 'No',
                        name: user.name,
                        last_name: user.last_name,
                        phone_number: user.phone_number,
                        username: user.username,
                        role_names: user.role_names.join(', '),
                        area_names: user.area_names.join(', '),
                        territory_names: user.territory_names.join(', '),
                        district_names: user.district_names.join(', '),
                        distribution_vehicle: (user.assigned_vehicle.length > 0) ? user.assigned_vehicle : 'None'
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>